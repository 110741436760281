import React, { useEffect } from 'react'
import Layout from '../components/Layout/Layout'

const NotFoundPage = () => {
  useEffect(() => {
    window.location.href = '/'
  }, [])

  return <Layout>" "</Layout>
}

export default NotFoundPage
